import Network from "./network";

export default class UserApi {

    static instance = null;

    static getInstance() {
        if (UserApi.instance == null) {
            UserApi.instance = new UserApi();
        }

        return this.instance;
    }

    removeFreePass(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription`, {
            method: "DELETE",
            body: JSON.stringify({
                userId: userId,
                freePassUntil: true
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response;
            })
            .catch(console.log);
    }

    updateFreePass(freePassTimestamp, userId, subscriptionPaymentId = null) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription/modify`, {
            method: "POST",
            body: JSON.stringify({
                userId: userId,
                freePassUntil: freePassTimestamp,
                subscriptionPaymentId: subscriptionPaymentId
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response;
            })
            .catch(console.log);
    }

    releaseUser(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription/release`, {
            method: "POST",
            body: JSON.stringify({
                userId: userId
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    cancelSubscription(userId, subscriptionPaymentId, mainCancellationReason, subCancellationReason, cancellationFreeText, endDate) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription/cancel`, {
            method: "POST",
            body: JSON.stringify({
                userId: userId,
                subscriptionPaymentId: subscriptionPaymentId,
                endDate: endDate,
                cancelReasonEnum: {
                    main: mainCancellationReason,
                    sub: subCancellationReason,
                    freeText: cancellationFreeText
                }
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response
        })
            .catch(console.log);
    }

    manualSubscription(userId, pricingPlanId, stationsToAdd, endDate, amount, method, userName = null, phone = null) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription/create`, {
            method: "POST",
            body: JSON.stringify({
                userId: userId,
                pricingPlanId: pricingPlanId,
                stationsToAdd: stationsToAdd,
                endDate: endDate,
                amount: amount,
                method: method,
                userName: userName,
                phone: phone
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    updateClientLogs(userId, value) {
        return fetch(`${Network.getInstance().getBaseUrl()}userClientLogs/${userId}`, {
            method: "PUT",
            body: JSON.stringify({isEnabled: value}),
            headers: Network.getInstance().getHeaders()
        })
            .then(function (response) {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            }).catch(console.log);
    }

    resetPassword(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/setDefaultPass/${userId}`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    revertPassword(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/revertPass/${userId}`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }
    updateEmail(userId, email) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/changeEmail/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                email: email
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            }).catch(console.log);
    }
    updateNote(userId, note) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription/modify`, {
            method: "POST",
            body: JSON.stringify({
                userId: userId,
                paymentNote: note
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response.json();
            }).catch(console.log);
    }

    deleteNote(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}subscription`, {
            method: "DELETE",
            body: JSON.stringify({
                userId: userId,
                paymentNote: true
            }),
            headers: Network.getInstance().getHeaders()
        })
            .then((response) => {
                if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                    Network.getInstance().removeAuthentication();
                    return null;
                }
                return response;
            }).catch(console.log);
    }

    deleteUser(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "DELETE",
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response;
        })
            .catch(console.log);
    }

    freezeUserAccount(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}/freezeAccount`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response;
        })
            .catch(console.log);
    }

    unFreezeUserAccount(userId) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}/unfreezeAccount`, {
            method: "GET",
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response;
        })
            .catch(console.log);
    }

    blockUser(userId, shouldBlock) {
        return fetch(`${Network.getInstance().getBaseUrl()}generalBlock/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                isBlocked: shouldBlock
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    updateIgnoreBillingMismatch(userId, ignoreBillingMismatchData) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                ignoreBillingMismatch: ignoreBillingMismatchData
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    setSubscriptionCouponCode(userId, subscriptionCouponCode) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                subscriptionCouponCode: subscriptionCouponCode
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    addBulkTokens(userId, tokensToAdd) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                availableBulkTokens: tokensToAdd
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    updateUserSlotConvention(userId, slotConventionToSet) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                slotConvention: slotConventionToSet
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    updateUserIgnoreOTP(userId, ignoreOTPNewVal) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                ignoreOTP: ignoreOTPNewVal
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }
    updateUser(userId, payload) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                [payload.key]: payload.value
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }


    updateMerchant(userId, payload,merchantId) {
        console.log("userApi",payload,merchantId)
        return fetch(`${Network.getInstance().getBaseUrl()}merchants/${merchantId}`, {
            method: "PUT",
            body: JSON.stringify({
                "userId":userId,
                [payload.key]: payload.value
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }


    deleteMerchant(userId,merchantId){
        return fetch(`${Network.getInstance().getBaseUrl()}merchants/account/${userId}`, {
            method: "DELETE",
            headers: Network.getInstance().getHeaders(),
            body: JSON.stringify({
                "merchantId":merchantId
            }),
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response;
        })
            .catch(console.log);
    }
    updateForceNylasExport(userId, isForceNylasExportNewVal) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                forceNylasExport: isForceNylasExportNewVal
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }





    updateUserShowStationsFirst(userId, newValue) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                showStationsFirst: newValue
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }


    updateUserIsEcomEligible(userId, newValue) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                isEcomEligible: newValue
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

    updateUserIsPunchcardsEnabled(userId, newValue) {
        return fetch(`${Network.getInstance().getBaseUrl()}users/${userId}`, {
            method: "PUT",
            body: JSON.stringify({
                isPunchcardsEnabled: newValue
            }),
            headers: Network.getInstance().getHeaders()
        }).then((response) => {
            if (Network.getInstance().isTokenExpiredOrInvalid(response.status)) {
                Network.getInstance().removeAuthentication();
                return null;
            }
            return response.json();
        })
            .catch(console.log);
    }

}
