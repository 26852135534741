import React from 'react';
import {Button} from '../../components/modal/buttons';
import Modal from '../../components/modal/modal';
import FormGroup from "@material-ui/core/FormGroup";
import {Label} from "@material-ui/icons";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Alert} from "@material-ui/lab";
import {TextField} from "@material-ui/core";

export default class ModalsManager {

    static instance = null;

    static getInstance = () => {
        if (ModalsManager.instance === null) {
            ModalsManager.instance = new ModalsManager();
        }

        return this.instance;
    }

    getManualSubscriptionModal = (onSaveClicked,
                                  onClose,
                                  pricingPlanValue,
                                  onPricingPlanIdChanged,
                                  onStationsToAddChanged,
                                  onAmountChanged,
                                  methodValue,
                                  onMethodValueChanged,
                                  onEndDateChanged,
                                  onPhoneChanged,
                                  onUsernameChanged,
                                  errorState) => {


        return <Modal onClose={onClose}>
            <Modal.Header>
                <Modal.Title>
                    Manual Subscription
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup style={{marginTop: '15px'}}>
                    <InputLabel id="pricing_plan_id">Pricing Plan</InputLabel>
                    <Select
                        labelId="pricing_plan_id"
                        id="pricing_plan_id"
                        value={pricingPlanValue}
                        onChange={onPricingPlanIdChanged}
                    >
                        <MenuItem value={1}>Plus</MenuItem>
                        <MenuItem value={2}>Team</MenuItem>
                        <MenuItem value={11}>Entry</MenuItem>
                    </Select>
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <Input style={{marginTop: '5px'}}
                           type="number"
                           inputProps={{
                               inputProps: {
                                   min: 0
                               }
                           }}
                           placeholder="Stations to Add"
                           onChange={val => onStationsToAddChanged(val)}
                    />
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <Input style={{marginTop: '5px'}}
                           type="number"
                           step="0.01"
                           inputProps={{
                               inputProps: {
                                   min: 0
                               }
                           }}
                           placeholder="Amount"
                           onChange={val => onAmountChanged(val)}
                    />
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <InputLabel id="method">Method</InputLabel>
                    <Select
                        labelId="method"
                        id="method"
                        value={methodValue}
                        onChange={onMethodValueChanged}
                    >
                        <MenuItem value={"credit card"}>Credit Card</MenuItem>
                        <MenuItem value={"cash"}>Cash</MenuItem>
                        <MenuItem value={"direct bank transfer"}>Direct Bank Transfer</MenuItem>
                        <MenuItem value={"paper check"}>Paper Check</MenuItem>
                    </Select>
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <Row style={{display: 'flex', alignItems: 'center'}}>
                        <Col>
                            <Typography component="p" style={{marginRight: '5px'}}>
                                <text style={{
                                    color: 'grey',
                                }}>
                                    End Date:
                                </text>
                            </Typography>
                        </Col>
                        <Col>
                            <Input style={{marginTop: '5px'}}
                                   type="date"
                                   placeholder="End Date"
                                   onChange={val => onEndDateChanged(val)}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <Input style={{marginTop: '5px'}}
                           type="tel"
                           placeholder="Phone (optional)"
                           onChange={val => onPhoneChanged(val)}
                    />
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <Input style={{marginTop: '5px'}}
                           type="text"
                           placeholder="Username (optional)"
                           onChange={val => onUsernameChanged(val)}
                    />
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    {
                        !!errorState ?
                            <Typography component="p" style={{marginRight: '5px'}}>
                                <text style={{
                                    color: 'red',
                                }}>
                                    {errorState}
                                </text>
                            </Typography>
                            :
                            null
                    }
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    btnStyle="primary"
                    onClick={onSaveClicked}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }

    getGeneratePaymentLinkModal = (onGenerateClicked,
                                   onClose,
                                   pricingPlanValue,
                                   onPricingPlanIdChanged,
                                   onStationsToAddChanged,
                                   errorState) => {

        return <Modal onClose={onClose}>
            <Modal.Header>
                <Modal.Title>
                    Generate Payment Link
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup style={{marginTop: '15px'}}>
                    <InputLabel id="pricing_plan_id">Pricing Plan</InputLabel>
                    <Select
                        labelId="pricing_plan_id"
                        id="pricing_plan_id"
                        value={pricingPlanValue}
                        onChange={onPricingPlanIdChanged}
                    >
                        <MenuItem value={11}>Entry</MenuItem>
                        <MenuItem value={1}>Plus</MenuItem>
                        <MenuItem value={2}>Team</MenuItem>
                        <MenuItem value={14}>Premium</MenuItem>
                        <MenuItem value={'14&coup2p'}>Premium 20%</MenuItem>
                        <MenuItem value={'14&coup3z'}>Premium 30%</MenuItem>
                        <MenuItem value={20}>Premium Payments</MenuItem>
                        <MenuItem value={'20&coup2p'}>Premium Payments 20%</MenuItem>
                        <MenuItem value={'02&coup3z'}>Premium Payments 30%</MenuItem>
                    </Select>
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <Input style={{marginTop: '5px'}}
                           type="number"
                           inputProps={{
                               inputProps: {
                                   min: 0
                               }
                           }}
                           placeholder="Stations to Add"
                           onChange={val => onStationsToAddChanged(val)}
                    />
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    {
                        !!errorState ?
                            <Typography component="p" style={{marginRight: '5px'}}>
                                <text style={{
                                    color: 'red',
                                }}>
                                    {errorState}
                                </text>
                            </Typography>
                            :
                            null
                    }
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    btnStyle="primary"
                    onClick={onGenerateClicked}
                >
                    Generate
                </Button>
            </Modal.Footer>
        </Modal>
    }

    getAlertModal = (title, message, severity, onOkClicked,
                     onClose) => {

        return <Modal onClose={onClose}>
            <Modal.Header style={{textAlign: 'center'}}>
                <Modal.Title>
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{direction: 'rtl'}}>
                <Alert severity={severity}>{message}</Alert>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    btnStyle="primary"
                    onClick={onOkClicked}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    }

    getCancelSubscriptionModal = (onSaveClicked,
                                  onClose,
                                  mainCancellationReason,
                                  onMainCancellationReason,
                                  subCancellationReason,
                                  onSubCancellationReason,
                                  onFreeTextChanged,
                                  onEndDateChanged,
                                  subCancellationMenuItems,
                                  shouldShowFreeText,
                                  errorState) => {

        return <Modal onClose={onClose}>
            <Modal.Header>
                <Modal.Title>
                    Cancel Subscription
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup style={{marginTop: '15px'}}>
                    <InputLabel id="main_cancellation_reason_id">Main cancellation reason</InputLabel>
                    <Select
                        labelId="main_cancellation_reason_id"
                        id="main_cancellation_reason_id"
                        value={mainCancellationReason}
                        onChange={onMainCancellationReason}
                    >
                        <MenuItem value={1}>Missing feature</MenuItem>
                        <MenuItem value={2}>Bug</MenuItem>
                        <MenuItem value={3}>Pricing</MenuItem>
                        <MenuItem value={4}>Personal</MenuItem>
                        <MenuItem value={5}>General</MenuItem>
                        <MenuItem value={6}>Other</MenuItem>
                    </Select>
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    <InputLabel id="sub_cancellation_reason_id">Sub cancellation reason</InputLabel>
                    <Select
                        labelId="sub_cancellation_reason_id"
                        id="sub_cancellation_reason_id"
                        value={subCancellationReason}
                        onChange={onSubCancellationReason}
                    >
                        {
                            subCancellationMenuItems
                                ?
                                subCancellationMenuItems.map(function (object, i) {
                                    return <MenuItem value={object.value}>{object.label}</MenuItem>;
                                })
                                :
                                null
                        }
                    </Select>
                </FormGroup>
                {
                    shouldShowFreeText
                        ?
                        <FormGroup style={{marginTop: '15px'}}>
                            <TextField style={{marginTop: '5px'}}
                                       multiline
                                       variant="outlined"
                                       type="text"
                                       placeholder="Add your text here"
                                       onChange={val => onFreeTextChanged(val)}
                            />
                        </FormGroup>
                        :
                        null
                }
                <FormGroup style={{marginTop: '15px'}}>
                    <Row style={{display: 'flex', alignItems: 'center'}}>
                        <Col>
                            <Typography component="p" style={{marginRight: '5px'}}>
                                <text style={{
                                    color: 'grey',
                                }}>
                                    End Date (optional):
                                </text>
                            </Typography>
                        </Col>
                        <Col>
                            <Input style={{marginTop: '5px'}}
                                   type="date"
                                   placeholder="End Date"
                                   onChange={val => onEndDateChanged(val)}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup style={{marginTop: '15px'}}>
                    {
                        !!errorState ?
                            <Typography component="p" style={{marginRight: '5px'}}>
                                <text style={{
                                    color: 'red',
                                }}>
                                    {errorState}
                                </text>
                            </Typography>
                            :
                            null
                    }
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    btnStyle="primary"
                    onClick={onSaveClicked}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    }

    getSubscriptionCouponModal = (onAddCouponClicked,
                                   onClose,
                                   subscriptionCouponValue,
                                   onCouponSelected) => {

        return <Modal onClose={onClose}>
            <Modal.Header>
                <Modal.Title>
                    Add Subscription Coupon
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormGroup style={{marginTop: '15px'}}>
                    <InputLabel id="subscription_coupon_id">Coupon</InputLabel>
                    <Select
                        labelId="subscription_coupon_id"
                        id="subscription_coupon_id"
                        value={subscriptionCouponValue}
                        onChange={onCouponSelected}
                    >
                        <MenuItem value={null}>No coupon</MenuItem>
                        <MenuItem value={'coup2p'}>20% off</MenuItem>
                        <MenuItem value={'coup3z'}>30% off</MenuItem>
                    </Select>
                </FormGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    btnStyle="primary"
                    onClick={onAddCouponClicked}
                >
                    Apply coupon
                </Button>
            </Modal.Footer>
        </Modal>
    }
}
