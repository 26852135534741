import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Col from "react-bootstrap/Col";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import momentTz from "moment-timezone";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import {Alert} from '@material-ui/lab';
import {Checkbox, FormControlLabel} from "@material-ui/core";

const useStyles = makeStyles({
    table: {},
});

const colStyle = {
    flexBasis: 0,
    flexGrow: 1
};

const SimpleTable = ({rows, titles}) => {

    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            {titles.map(title => (
                <div style={{fontSize: '20px', paddingLeft: '20px', paddingTop: '20px', fontWeight: 500}}>{title}</div>
            ))}
            <Table className={classes.table} aria-label="simple table">
                <TableBody>
                    {rows.map(row => (
                        <TableRow key={row.name}>
                            {
                                !!row.name
                                    ?
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    :
                                    null
                            }

                            <TableCell>
                                {
                                    !!row.actionType ?

                                        getComponentByActionType(row)

                                        :

                                        <p>{row.val}</p>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    function getComponentByActionType(rowData) {
        let actionType = rowData.actionType;
        switch (actionType) {
            case "button":
                return getButtonActionType(rowData);
            case "datePicker":
                return getDatePickerActionType(rowData);
            case "alert":
                return getAlert(rowData);
            case "info":
                return getInfo(rowData);
            case "checkbox":
                return getCheckbox(rowData);
            default:
                return <p/>
        }
    }

    function getButtonActionType(rowData) {
        function createButton(name, action) {
            return <Col style={{marginLeft: '15px'}}>
                {
                    !!rowData.action
                        ?
                        <Button variant="contained" color="primary" onClick={() => {
                            action(name, rowData.data)
                        }}>
                            {name}
                        </Button>
                        :
                        null
                }
            </Col>
        }

        return (
            <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col>
                    <Typography component="p">
                        {rowData.val}
                    </Typography>
                </Col>
                {
                    Array.isArray(rowData.actionName)
                        ?
                        rowData.actionName.map(item => {
                            return createButton(item, rowData.action);
                        })
                        :
                        <Col style={{marginLeft: '15px'}}>
                            {
                                !!rowData.action
                                    ?
                                    <Button variant="contained" color="primary" onClick={() => {
                                        rowData.action(rowData.data)
                                    }}>
                                        {rowData.actionName}
                                    </Button>
                                    :
                                    null
                            }
                        </Col>
                }
            </Row>
        );
    }

    function getDatePickerActionType(rowData) {
        let user = rowData.data;
        return (
            <Typography variant="body2" component="p">
                {shouldShowCalendar(user) ?

                    <Row style={{display: 'inline-flex', alignItems: 'center'}}>
                        <Col style={colStyle}>
                            <DatePicker
                                onChange={rowData.action}
                            />
                        </Col>
                        <Col style={colStyle}>
                            <Button style={{marginLeft: '10px'}}
                                    variant="contained" color="primary"
                                    onClick={() => rowData.action(new Date("01/01/2100"))}>Free Usage</Button>
                        </Col>
                    </Row>

                    :

                    <Row style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
                        <Col style={colStyle}>
                            <text style={{
                                color: 'green',
                                fontWeight: 'bold'
                            }}>  {momentTz(user.freePassUntil).tz("Asia/Jerusalem").format("DD/MM/YY")} </text>
                        </Col>
                        <Col style={colStyle}>
                            <Button style={{padding: '0'}}
                                    variant={'contained'}
                                    onClick={rowData.action}>X</Button>
                        </Col>
                    </Row>


                }
            </Typography>
        );

        function shouldShowCalendar(user) {
            return !user.freePassUntil || user.freePassUntil < new Date().getTime()
        }
    }

    function getAlert(rowData) {
        return (
            <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col>
                    <Typography component="p">
                        {rowData.val}
                    </Typography>
                </Col>
                <Col style={{marginLeft: '15px'}}>
                    <Alert severity="error">{rowData.actionName}</Alert>

                </Col>
            </Row>
        );
    }

    function getCheckbox(rowData) {
        return (
            <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col style={{marginLeft: '15px'}}>
                    <div>
                        <FormControlLabel
                            control={<Checkbox size="normal"
                                               checked={rowData.data.ignoreStations}
                                               onChange={e => rowData.action('ignoreStations', e.target.checked)}/>}
                            label='Ignore stations'
                        />
                    </div>
                </Col>
                <Col style={{marginLeft: '15px'}}>
                    <div>
                        <FormControlLabel
                            control={<Checkbox size="normal"
                                               checked={rowData.data.ignoreServices}
                                               onChange={e => rowData.action('ignoreServices', e.target.checked)}/>}
                            label='Ignore services'
                        />
                    </div>
                </Col>
            </Row>
        );
    }

    function getInfo(rowData) {
        return (
            <Row style={{display: 'flex', alignItems: 'center'}}>
                {
                    !!rowData.val ?
                        <Col>
                            <Typography component="p">
                                {rowData.val}
                            </Typography>
                        </Col>
                        :
                        null
                }
                <Col style={{marginLeft: '15px'}}>
                    <Alert severity="info">{rowData.actionName}</Alert>

                </Col>
            </Row>
        );
    }

};
export default SimpleTable;
