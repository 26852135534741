export default class UserUtils {

    static instance = null;

    static getInstance() {
        if (UserUtils.instance == null) {
            UserUtils.instance = new UserUtils();
        }

        return this.instance;
    }

    isLocalHost() {
        return window.location.hostname === 'qa-monitor.plannieapp.com' ||
            window.location.hostname === 'localhost' ||
            // [::1] is the IPv6 localhost address.
            window.location.hostname === '[::1]' ||
            // 127.0.0.1/8 is considered localhost for IPv4.
            window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
            )
    }
    getBlockingType(user) {
        if (user.blockingType === 0) {
            return "TIMEOUT BLOCK";
        } else if (user.blockingType === 1) {
            return "HARD BLOCK";
        }
        return "RELEASE";
    }

}
